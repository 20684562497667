<template>
  <div class="register-container">
    <div class="bgcolor"></div>
    <div class="login-label">
      <div class="title-container">
        <h3 class="title">江苏省工程建设企业技术标准认证公告系统</h3>
        <div class="first">用户注册</div>
      </div>
      <div class="shuru">
        <el-form
          ref="ruleForm"
          :model="ruleForm"
          :rules="rules"
          class="register-form"
          label-width="100px"          
        >
          <el-form-item label="用户名" prop="username">
            <el-input v-model="ruleForm.username"></el-input>
          </el-form-item>
          <el-form-item label="所属企业" prop="right_company">  
            <el-input v-model="ruleForm.right_company" placeholder="请填写企业名称"></el-input>
          </el-form-item>
          <!-- <a href="" size="5px">没找到企业？</a> -->
          <el-form-item label="密码" prop="password">
            <el-input v-model="ruleForm.password" show-password placeholder="请输入密码"></el-input>
          </el-form-item>
          <el-form-item label="确认密码" prop="checkPass">
            <el-input v-model="ruleForm.checkPass" show-password placeholder="请确认密码"> </el-input>
          </el-form-item>
          <el-form-item label="联系方式" prop="telephone">
          <el-input v-model="ruleForm.telephone" placeholder="请输入联系方式"></el-input>
        </el-form-item>        
        <el-form-item label="所在城市" prop="city">
          <el-select v-model="ruleForm.city" placeholder="请选择所在城市">
            <el-option
              v-for="item in cityOptions"
              :key="item.name"
              :label="item.name"
              :value="item.name"
            ></el-option>
          </el-select>
        </el-form-item>
          <el-button
            class="regitserbtn"
            type="primary"
            @click="submitForm('ruleForm')"
          >
            注册
          </el-button>
        </el-form>
        <div class="logcont2">

          <div class="forget">
            已有账号，
            <router-link to="../login">
              <span style="color: #3082e2;text-decoration: none;">点击登录</span>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import JiangSu from "@assets/jiangsu_se.json";
import { register_putong } from "@api/user";
export default {
  data() {
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.ruleForm.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      searchText: '', // 输入框内容
      searchResult: [], // 搜索结果
      labelPosition: "top",
      cityOptions: JiangSu.city,
      type: [
        {
          type: "array",
          required: true,
          message: "请选择角色",
          trigger: "change",
        },
      ],

      ruleForm: {
        username: "",
        password: "",
        checkPass: "",
        right_company: "",
        telephone:"",
        city:""

      },
      rules: {
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
          { min: 3, max: 20, message: "长度在 3 到 20 个字符", trigger: "blur" },
        ],
        right_company: [
          { required: true, message: "请填写企业名称" },
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { min: 6, max: 20, message: "长度在 6到 20个字符", trigger: "blur" },
        ],
        checkPass: [
          { required: true, message: "请再次输入密码", trigger: "blur" },
          { validator: validatePass2, trigger: "blur" },
        ],
        telephone: [
          { required: true, message: "请输入联系方式" },
        ],
        city: [
          { required: true, message: "请选择所在城市" },
        ],
      },
    };
  },
  methods: {
    // 输入框内容改变时的处理函数
    handleSearch() {
      if (!this.searchText) { // 如果输入框为空，则清空搜索结果
        this.searchResult = [];
        return;
      }
      // 向后端发送请求，获取相关数据，然后将搜索结果赋值给 searchResult
      this.$http.get('/api/search', { params: { keyword: this.searchText } }).then(res => {
        this.searchResult = res.data;
      }).catch(err => {
        console.log(err);
      });
    },
    handleAvatarSuccess(res, file) {
      this.ruleForm.avatar = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // alert('submit!');
          //console.log(this.ruleForm)
          // this.ruleForm = {
          //   ...this.ruleForm,
          //   class: 4,
          // };
          let params = {
            username: this.ruleForm.username,
            password: this.ruleForm.password,
            class: 4,
            address: "1",
            city: this.ruleForm.city,
            company: this.ruleForm.right_company,
            telephone: this.ruleForm.telephone,
            loginType:1
          };
          register_putong(params)
            .then((res) => {
              console.log(res);
              this.$message.success("注册成功");
              this.$router.push({ path: "/login" });
            })
            .catch((err) => {
              //console.log(err)
              this.$message.error(err.message);
            });
        } else {
          //console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>
<style scoped>
/deep/.el-select {
  width: 100%;
}
/deep/.el-form-item__label {
  font-size: 16px;
  font-weight: bold;
  color: #000000;
}
</style>
<style lang="scss" scoped>
$bg: #ffffff;
$dark_gray: #889aa4;
$light_gray: #eee;
.bgcolor {
  width: 50%;
  height: 100vh;
  background-color: #3082e2;
  // background-image: url("https://cloud.cdmp.tech/ccf0fa5c5dcfcc8b152e36068b699f00.png");
  // background-size: cover;
}
.shuru {
  margin: 0 auto;
  width: 360px;
  height: 360px;
  border-radius: 20px;
  // padding: 70px;
}
.logcont2 {
  margin-top: 27px;
  // width: 400px;
  // display: flex;
  // flex-direction: row;
  // justify-content: space-between;
  .forget {
    font-size: 14px;
    font-weight: 400;
    color: #000000;
    text-align: center;
  }
}
.login-label {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.register-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100vh;
  background-size: 100% 100%;

  .register-form {
    position: relative;
    // width: 520px;
    // max-width: 100%;
    // padding: 160px 35px 0;
    margin: 0 auto;
    overflow: hidden;
  }
}
.title-container {
  position: relative;

  .title {
    font-size: 36px;
    color: #333333;
    margin: 0px auto 20px auto;
    text-align: center;
    font-weight: bold;
  }
}
.regitserbtn {
  width: 100%;
  background: #3082e2;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.first {
  font-size: 24px;
  font-weight: bold;
  color: #000000;
  margin: 40px 0;
  text-align: center;
}
</style>
